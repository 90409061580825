<ng-container *ngIf="data">
  <ng-container *ngIf="!pending">

    <div class="animation-out" *ngIf="!authUrl?.length && !authFailed">
      <app-form-select *ngIf="nationalityField" [model]="guest" [key]="'nationality'" name="nationality{{ index }}" [label]="nationalityField.name" [errorLabel]="'validation.nationality'" [options]="data.countriesNat" [showIcons]="true" [tooltip]="nationalityField.descriptionn" [required]="nationalityField.required" [redesign]="true" (optionValue)="checkAuthMethods(isIdnowActive)"></app-form-select>

      <ng-container *ngIf="verificationOptions?.length; else no_validation">
        <div class="animation-in" *ngIf="guest.nationality">
          <app-description-label *ngIf="verificationOptions?.length > 1" class="description-label" [description]="'service.check_in.authentication.description' | translate" [tooltip]="'service.check_in.authentication.tooltip' | translate"></app-description-label>
          <div>
            <app-verification-box-group [options]="verificationOptions" [(ngModel)]="selVerification" (click)="IdfMethod()"></app-verification-box-group>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="iframe_container" *ngIf="authUrl?.length && !authFailed">
      <iframe [src]="authUrl | safe" id="external_link" allow="camera *; microphone *" allowfullscreen=""></iframe>
    </div>

    <div *ngIf="authFailed" class="animation-in">
      <div class="desc">
        <div class="instructions not-first-instruction" [innerHTML]="ui_messages.auth_failed?.content"></div>
      </div>
      <div class="button-row">
        <button type="button" class="btn-filled" (click)="retryAuth()" translate>service.folios.retry</button>
        <button type="button" class="btn-outline" (click)="manual()" *ngIf="authRequired === 'optional'" translate>service.check_in.authentication.manual</button>
      </div>
    </div>
  </ng-container>

  <app-loader [text]="'service.check_in.authentication.pending'" *ngIf="pending"></app-loader>
</ng-container>

<ng-template #no_validation>
  <button class="btn-filled" [class.small-width]="!cicoService.hideNext" [disabled]="!guest.nationality || cicoService.disableNext" (click)="manual()">
    {{ 'misc.next' | translate }}
    <i class="fa-solid fa-chevron-right fa-lg"></i>
  </button>
</ng-template>